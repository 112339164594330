
<p *ngIf="answerer && guessingWord !== null">
  Mot à faire deviner : {{ guessingWord }}.
</p>
<p *ngIf="answerer && guessingWord === null">
  Tu dois deviner le mot.
</p>
<p *ngIf="!answerer" role="status">
  <span class="spinner-border align-middle"></span>
  En attente de joueurs...
</p>
<form class="card-deck" (ngSubmit)="sendWord()" [formGroup]="form">
  <ng-container *ngFor="let player of players">
    <div class="player card" [class.player-is-answerer]="player.id === answerer?.id">
      <div class="card-body">
        <h5 class="player-name card-title">{{ player.name }}</h5>
        <div class="card-text" hidden="hidden">
          {{ player | json }}
        </div>
        <p *ngIf="!player.current && answerer" class="player-word card-text">
          <ng-container *ngIf="player.displayedWord === null && player.id !== answerer?.id">
            <span role="status">
              <span class="spinner-border spinner-border-sm"></span>
              {{ player.displayedWord || 'En attente...' }}
            </span>
          </ng-container>
          <ng-container *ngIf="player.displayedWord !== null || player.id === answerer?.id">
            <span [class.word-wrong]="player.displayedWord && player.id === answerer?.id">
              {{ player.displayedWord || '' }}
            </span>
          </ng-container>
        </p>
        <div *ngIf="player.current && answerer" class="player-word card-text input-group">
          <input type="text"
                 class="form-control"
                 name="word"
                 formControlName="word"
                 required="required"
                 pattern="^\s*\S+\s*$"
                 [readonly]="readonly"
                 [class.is-invalid]="isInvalid"
          />
          <span class="input-group-append" *ngIf="!readonly">
            <button class="btn btn-primary" [disabled]="form.pristine"><span>Valider</span></button>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</form>
