import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JustoneComponent } from './justone/justone.component';
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [JustoneComponent],
  exports: [
    JustoneComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class JustoneModule { }
