import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GameBackendService} from "../../core/game-backend.service";
import {Player, ReadonlyPlayer} from "../model/player";
import {
  ClueMessageList,
  PlayerStatusMessage,
  WordMessage,
  WordValidationMessage
} from "../model/message";
import {JustoneSocket} from "../socket/justone-socket";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-justone',
  templateUrl: './justone.component.html',
  styleUrls: ['./justone.component.scss']
})
export class JustoneComponent implements OnInit, OnDestroy {
  private _gameId: string;
  private _players: Map<string, Player> = new Map();
  private _playersArray: ReadonlyArray<Player> = [];
  private _socket: JustoneSocket;
  private _answerer: ReadonlyPlayer;
  private _guessingWord: string;
  private _form: FormGroup;
  private _clueReceived: boolean = false;

  constructor(private _backend: GameBackendService, public _fb: FormBuilder) {
    this._socket = new JustoneSocket(_backend);
    this._socket.onPlayerConnect.subscribe((message) => this.playerConnect(message));
    this._socket.onPlayerDisconnect.subscribe((message) => this.playerDisconnect(message));
    this._socket.onNewWordReceived.subscribe((message) => this.newWordReceived(message));
    this._socket.onClueReceived.subscribe((message) => this.clueReceived(message));
    this._socket.onMessageValid.subscribe((message) => this.messageValid(message));
    this._socket.onMessageInvalid.subscribe((message) => this.messageInvalid(message));
  }

  public ngOnInit(): void {
    this.createForm();
    this._socket.open(this._gameId);
  }

  public ngOnDestroy(): void {
    this._socket.close();
  }

  public createForm() {
    this._form = this._fb.group({
      word: ['', Validators.required, Validators.pattern(/^\s*\S+\s*$/)],
    });
  }

  private playerConnect(message: PlayerStatusMessage): void {
    this.addPlayer(message.player);
  }
  private playerDisconnect(message: PlayerStatusMessage): void {
    this.removePlayer(message.player.id);
  }
  private newWordReceived(message: WordMessage): void {
    this._answerer = message.player;
    this._guessingWord = message.word;
  }
  private clueReceived(message: ClueMessageList): void {
    for (const clue of message) {
      if (!this._players.has(clue.player.id)) {
        this.addPlayer(clue.player);
      }
      this._players.get(clue.player.id).displayedWord = clue.clue ?? '';
      if (this._players.get(clue.player.id).current) {
        this._form.get('word').setValue(this._players.get(clue.player.id).displayedWord);
      }
    }
    this._clueReceived = true;
  }
  private messageValid(message: WordValidationMessage): void {
    for(const player of this._players.values()) {
      player.displayedWord = null;
      this._form.reset();
    }
    this._answerer = null;
    this._clueReceived = false;
  }
  private messageInvalid(message: WordValidationMessage): void {
    this._players.get(this._answerer.id).displayedWord = message.answer;
    if (this._players.get(this.answerer.id).current) {
      this._form.get('word').setErrors({
        wrong: true
      });
    }
  }

  private addPlayer(player: Player): void {
    player.displayedWord = null;
    this._players.set(player.id, player);
    this._playersArray = Array.from(this._players.values());
  }
  private removePlayer(playerId: string): void {
    this._players.delete(playerId);
    this._playersArray = Array.from(this._players.values());
  }

  public sendWord(): void {
    this._socket.sendMessage(this._form.get('word').value);
    this._form.markAsPristine();
    this._form.markAsUntouched();
  }

  public get gameId(): string {
    return this._gameId;
  }

  @Input('game-id')
  public set gameId(gameId: string) {
    this._gameId = gameId;
  }

  @Output()
  public get onClose(): EventEmitter<void> {
    return this._socket.onClose;
  }

  public get players(): ReadonlyArray<Player> {
    return this._playersArray;
  }

  public get answerer(): ReadonlyPlayer {
    return this._answerer;
  }

  public get guessingWord(): string {
    return this._guessingWord;
  }

  public get readonly(): boolean {
    return !this.answerer
      || this._players.get(this.answerer.id).current && !this._clueReceived
      || !this._players.get(this.answerer.id).current && this._clueReceived;
  }

  public get form(): FormGroup {
    return this._form;
  }

  public get isInvalid(): boolean {
    const word = this._form.get('word');
    return word.untouched && word.invalid && word.value;
  }
}
