import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GameInstance} from "../core/game-instance";

@Component({
  selector: 'app-taskbar',
  templateUrl: './taskbar.component.html',
  styleUrls: ['./taskbar.component.scss']
})
export class TaskbarComponent implements OnInit {
  public isNavbarCollapsed=true;
  private _onJoinGame: EventEmitter<GameInstance> = new EventEmitter();

  constructor(private _httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  public newGame(): void {
    this._httpClient.post('/ws/justone', '', {responseType: 'text'}).subscribe(
      (id: string) => this.joinGame(id)
    );
  }

  public promptJoinGame(): void {
    let id = prompt('Identifiant du jeu');
    if(id !== null) {
      this.joinGame(id);
    }
  }

  private joinGame(id:string): void {
    this._onJoinGame.emit({
      id,
      gameName: 'justone'
    });
  }

  @Output()
  public get onJoinGame(): EventEmitter<GameInstance> {
    return this._onJoinGame;
  }
}
