import {ReadonlyPlayer} from "./player";

export enum MessageType {
  ClueMessageList = 'clue',
  PlayerStatusMessage = 'player',
  WordMessage = 'word',
  WordValidationMessage = 'word-validation',
}
export interface MessageWrapper {
  readonly type: MessageType;
  readonly body: ClueMessageList|PlayerStatusMessage|WordMessage|WordValidationMessage;
}

export interface ClueMessageList extends ReadonlyArray<ClueMessage> {}
export interface ClueMessage {
  readonly clue: string;
  readonly player: ReadonlyPlayer;
}

export enum PlayerStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}
export interface PlayerStatusMessage {
  readonly player: ReadonlyPlayer;
  readonly status: PlayerStatus;
}

export interface WordMessage {
  readonly word: string;
  readonly player: ReadonlyPlayer;
}

export interface WordValidationMessage {
  readonly valid: boolean;
  readonly answer: string;
}
