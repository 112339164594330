import {Component, OnInit} from '@angular/core';
import {GameInstance} from "./core/game-instance";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {filter, concatMap} from "rxjs/operators";
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'justone-client';

  private _gameList: Map<string, GameInstance> = new Map();
  private _gameListArray: ReadonlyArray<GameInstance> = [];

  constructor(private _httpClient: HttpClient) {
  }

  public ngOnInit(): void {
    this._httpClient.get(environment.sessionBackend, {responseType: 'text'})
      .pipe(filter((name) => !name))
      .pipe(concatMap(() => {
        const name = prompt('Pseudo');
        if (!name) {
          return EMPTY;
        }
        const body = new FormData();
        body.append('name', name);
        return this._httpClient.post(environment.sessionBackend, body, {responseType: 'text'});
      }))
      .subscribe();
  }

  public get gameList(): ReadonlyArray<GameInstance> {
    return this._gameListArray;
  }
  public addGame(game: GameInstance): void {
    this._gameList.set(game.id, game);
    this._gameListArray = Array.from(this._gameList.values());
  }
  public removeGame(gameId: string): void {
    this._gameList.delete(gameId);
    this._gameListArray = Array.from(this._gameList.values());
  }
}
