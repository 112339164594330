import {EventEmitter} from "@angular/core";
import {
  ClueMessageList, MessageType,
  MessageWrapper, PlayerStatus,
  PlayerStatusMessage,
  WordMessage,
  WordValidationMessage
} from "../model/message";
import {GameBackendService} from "../../core/game-backend.service";
import {Subject, Subscription} from "rxjs";
import {WebSocketSubject} from "rxjs/webSocket";
import {finalize, tap} from "rxjs/operators";

export class JustoneSocket {
  private _onPlayerConnect: EventEmitter<PlayerStatusMessage> = new EventEmitter();
  private _onPlayerDisconnect: EventEmitter<PlayerStatusMessage> = new EventEmitter();
  private _onNewWordReceived: EventEmitter<WordMessage> = new EventEmitter();
  private _onClueReceived: EventEmitter<ClueMessageList> = new EventEmitter();
  private _onMessageValid: EventEmitter<WordValidationMessage> = new EventEmitter();
  private _onMessageInvalid: EventEmitter<WordValidationMessage> = new EventEmitter();
  private _onClose: EventEmitter<void> = new EventEmitter();

  private _backendSubscription: Subscription;
  private _websocket: Subject<string>;

  constructor(private _backend: GameBackendService) {
  }

  public sendMessage(message: string): void {
    this._websocket.next(message);
  }

  private receiveMessage(message: MessageWrapper): void {
    switch (message.type) {
      case MessageType.PlayerStatusMessage:
        const playerStatus = message.body as PlayerStatusMessage;
        if (PlayerStatus.CONNECTED === playerStatus.status) {
          this._onPlayerConnect.emit(playerStatus);
        } else if (PlayerStatus.DISCONNECTED === playerStatus.status) {
          this._onPlayerDisconnect.emit(playerStatus);
        }
        break;
      case MessageType.WordMessage:
        this._onNewWordReceived.emit(message.body as WordMessage);
        break;
      case MessageType.ClueMessageList:
        this._onClueReceived.emit(message.body as ClueMessageList);
        break;
      case MessageType.WordValidationMessage:
        const wordValidation = message.body as WordValidationMessage;
        if (wordValidation.valid) {
          this._onMessageValid.emit(wordValidation);
        } else {
          this._onMessageInvalid.emit(wordValidation);
        }
        break;
    }
  }

  public get onPlayerConnect(): EventEmitter<PlayerStatusMessage> {
    return this._onPlayerConnect;
  }

  public get onPlayerDisconnect(): EventEmitter<PlayerStatusMessage> {
    return this._onPlayerDisconnect;
  }

  public get onNewWordReceived(): EventEmitter<WordMessage> {
    return this._onNewWordReceived;
  }

  public get onClueReceived(): EventEmitter<ClueMessageList> {
    return this._onClueReceived;
  }

  public get onMessageValid(): EventEmitter<WordValidationMessage> {
    return this._onMessageValid;
  }

  public get onMessageInvalid(): EventEmitter<WordValidationMessage> {
    return this._onMessageInvalid;
  }

  public get onClose(): EventEmitter<void> {
    return this._onClose;
  }

  public open(gameId: string): void {
    if (this._websocket) {
      throw 'Socket already opened';
    }
    const websocket = this._backend.connect('justone', gameId);
    this._backendSubscription = websocket.pipe(tap(
      (message: MessageWrapper) => this.receiveMessage(message)
    )).pipe(finalize(
      () => this.close()
    )).subscribe();
    this._websocket = websocket as WebSocketSubject<string>;
  }

  public close(): void {
    if (this._backendSubscription) {
      this._backendSubscription.unsubscribe();
    }
    if (this._websocket) {
      this._websocket.complete();
    }
    this._onPlayerConnect.complete()
    this._onPlayerDisconnect.complete()
    this._onNewWordReceived.complete()
    this._onClueReceived.complete()
    this._onMessageValid.complete()
    this._onMessageInvalid.complete()
    this._onClose.next()
    this._onClose.complete()
  }
}
