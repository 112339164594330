import { Injectable } from '@angular/core';
import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {environment} from "../../environments/environment";
import {MessageWrapper} from "../justone/model/message";

@Injectable({
  providedIn: 'root'
})
export class GameBackendService {

  constructor() { }

  public connect(gameName: string, instanceId: string): WebSocketSubject<MessageWrapper|string> {
    const url = environment.gamesBackend
      + '/' + gameName
      + '/' + instanceId
    return webSocket(url);
  }
}
