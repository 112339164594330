<span class="navbar-brand">Just One</span>
<button (click)="isNavbarCollapsed = !isNavbarCollapsed" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button>
<div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarNav">
  <ul class="navbar-nav">
    <li class="nav-item">
      <button class="btn btn-primary w-100" (click)="newGame()"><span>Nouveau Jeu</span></button>
    </li>
    <li class="nav-item">
      <button class="btn btn-primary w-100" (click)="promptJoinGame()"><span>Joindre</span></button>
    </li>
  </ul>
</div>
