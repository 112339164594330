import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameInstance} from "../core/game-instance";

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
  private _gameInstance: GameInstance;
  private _onClose: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public get gameInstance(): GameInstance {
    return this._gameInstance;
  }

  @Input('game-instance') public set gameInstance(gameInstance: GameInstance) {
    this._gameInstance = gameInstance;
  }

  @Output()
  public get onClose(): EventEmitter<void> {
    return this._onClose;
  }

  public close(): void {
    this._onClose.emit();
  }
}
